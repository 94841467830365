@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.mailchimp {
}

.mailchimp input {
  border: 1px solid #eee;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width: 80%;
  outline: none;
}

.mailchimp button {
  border: 0;
  background-color: #4c4c4c;
  color: #fff;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  border-radius: 5px;
  transition: background-color .4s ease-out;
}

.mailchimp button:hover {
  background-color: #333;
}
